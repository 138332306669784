import { ThemeConfig } from '@icp/angular/theming';
import { GetEconomyDetailsDto } from '@icp/interfaces';

export function createEconomyThemeConfig(economy?: GetEconomyDetailsDto | null): Required<ThemeConfig> | null {
    if (economy) {
        return {
            primary: economy.colorPrimary ?? DEFAULT_THEME.primary,
            primaryContrast: economy.colorPrimaryText ?? DEFAULT_THEME.primaryContrast,
            accent: economy.colorSecondary ?? DEFAULT_THEME.accent,
            accentContrast: DEFAULT_THEME.accentContrast,
        };
    }
    return null;
}

export const DEFAULT_THEME: Required<ThemeConfig> = {
    primary: '#6a51a4',
    primaryContrast: '#ffffff',
    accent: '#fed718',
    accentContrast: '#444444',
};
